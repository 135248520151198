import { QueryKey, useMutation } from '@tanstack/vue-query'
import {
  computed,
  MaybeRefOrGetter,
  reactive,
  Ref,
  toValue,
  MaybeRef,
} from 'vue'
import { z } from 'zod'
import { useDefaultMutationHandler } from '@/modules/base/composable/useDefaultMutationHandler'
import { DeepPartial } from '@/modules/base/utils/typescript'
import {
  ActionForBackendData,
  ActionMove,
  actionFullDataSchema,
  ActionData,
  ActionState,
  commentItemSchema,
} from './useAction.types'
import { auditEventSchema } from './useActionAuditEvent.types'
import {
  composeTypedQueryWithCompanyId,
  universalTypedFetch,
  stringifyBody,
  createMutationFn,
  useComposeInvalidation,
} from './utils/factory'
import { Endpoint, useUrl } from './utils/url'

// TODO: remove
// * export all types
export * from './useAction.types'

// ⬇️ Query ⬇️
export const useQueryAction = (actionId: Ref<number | null>) =>
  composeTypedQueryWithCompanyId(
    [Endpoint.CompanyActions, actionId],
    actionFullDataSchema
  )(
    reactive({
      enabled: computed(() => actionId.value !== null),
    })
  )

export const useQueryActionAuditEvents = (actionId: Ref<number | null>) =>
  composeTypedQueryWithCompanyId(
    [Endpoint.CompanyActions, actionId, Endpoint.AuditEvents],
    auditEventSchema.array()
  )()

// ⬇️ Core Action Mutations ⬇️
export const useMutationActionDelete = (actionId: Ref<number | null>) => {
  const { invalidate } = useActionInvalidation()
  const { createUrlWithCompanyId } = useUrl()
  const { defaultMutationHandler } = useDefaultMutationHandler(
    true,
    'global.toastMessages.deleteActionSuccess',
    'global.toastMessages.deleteActionFailure'
  )

  return useMutation({
    mutationFn: createMutationFn({
      path: () => createUrlWithCompanyId(Endpoint.CompanyActions, actionId),
      method: 'DELETE',
    }),
    onSettled: invalidate,
    ...defaultMutationHandler,
  })
}

export const useMutationActionCreate = () => {
  const { invalidate } = useActionInvalidation()
  const { createUrlWithCompanyId } = useUrl()

  return useMutation<ActionData, Error, DeepPartial<ActionForBackendData>>({
    mutationFn: createMutationFn({
      path: () => createUrlWithCompanyId(Endpoint.CompanyActions),
      method: 'POST',
      body: (companyAction) => ({ companyAction }),
      returnedSchema: actionFullDataSchema,
    }),
    onSettled: invalidate,
  })
}

export const useMutationActionEdit = (
  actionId: MaybeRefOrGetter<number>,
  isInvalidationEnabled = true,
  showSuccessToast = true
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrlWithCompanyId } = useUrl()
  const { defaultMutationHandler } = useDefaultMutationHandler(showSuccessToast)

  return useMutation<ActionData, Error, DeepPartial<ActionForBackendData>>({
    mutationFn(companyAction) {
      const url = createUrlWithCompanyId(
        Endpoint.CompanyActions,
        toValue(actionId)
      )
      return universalTypedFetch(url, actionFullDataSchema, {
        method: 'PATCH',
        body: stringifyBody({ companyAction }),
      })
    },
    onSettled() {
      if (toValue(isInvalidationEnabled)) {
        invalidate()
      }
    },
    ...defaultMutationHandler,
  })
}

// ⬇️ Action Drag and Drop Mutations ⬇️
export const useMutationActionMove = (actionId: MaybeRefOrGetter<number>) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'move')

  return useMutation({
    mutationFn(variables: ActionMove) {
      return universalTypedFetch(createUrl(), z.undefined(), {
        method: 'POST',
        body: stringifyBody(variables),
      })
    },
    onSettled: invalidate,
  })
}

export const useMutationActionDuplicate = (actionId: Ref<number | null>) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'duplicate')

  return useMutation({
    mutationFn: createMutationFn({
      path: createUrl,
      method: 'POST',
      returnedSchema: actionFullDataSchema,
    }),
    onSettled: invalidate,
  })
}

// ⬇️ Action State Mutations ⬇️
export const useMutationActionSetActiveState = (
  actionId: MaybeRef<number | null>
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'active_changes')

  return useMutation({
    mutationFn: createMutationFn({
      path: createUrl,
      method: 'POST',
      body: (variables: { active: boolean }) => variables,
    }),
    onSettled: invalidate,
  })
}

export const useMutationActionStateChange = (
  actionId: MaybeRefOrGetter<number>
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'state_changes')

  return useMutation<undefined, Error, ActionState>({
    mutationFn: createMutationFn({
      path: createUrl,
      method: 'POST',
      body: (state) => ({
        stateChange: { state },
      }),
    }),
    onSettled: invalidate,
  })
}

// ⬇️ Assignments Mutations ⬇️
export const useMutationActionUserAssignments = (
  actionId: MaybeRefOrGetter<number>
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'user_assignments')

  return useMutation({
    mutationFn(userId: number | null) {
      return universalTypedFetch(createUrl(), z.undefined(), {
        method: 'POST',
        body: stringifyBody({
          userAssignment: { userId },
        }),
      })
    },
    onSettled: invalidate,
  })
}

export const useMutationActionLocationAssign = (
  actionId: MaybeRefOrGetter<number>
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'location_assignments')

  return useMutation({
    mutationFn(locationId: number | null) {
      return universalTypedFetch(createUrl(), z.undefined(), {
        method: 'POST',
        body: stringifyBody({
          locationAssignment: { locationId },
        }),
      })
    },
    onSettled: invalidate,
  })
}

// ⬇️ Comments Mutations ⬇️
export const useMutationActionCommentsAdd = (actionId: Ref<number | null>) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'comments')

  return useMutation({
    mutationFn: createMutationFn({
      path: createUrl,
      method: 'POST',
      body: (variables: { content: string }) => ({
        comment: variables,
      }),
      returnedSchema: commentItemSchema,
    }),
    onSettled: invalidate,
  })
}

export const useMutationActionCommentsDelete = (
  actionId: Ref<number | null>
) => {
  const { invalidate } = useActionInvalidation()
  const { createUrl } = useActionUrl(actionId, 'comments')

  return useMutation({
    mutationFn: createMutationFn<{ id: number }>({
      path: (context) => createUrl() + `/${context.id}`,
      method: 'DELETE',
    }),
    onSettled: invalidate,
  })
}

// ⬇️ Utils ⬇️
export const useActionInvalidation = useComposeInvalidation(
  ({ withCompanyId }) => [
    withCompanyId([Endpoint.CompanyActions]),
    withCompanyId([Endpoint.YearlyEmissionsStatistics]),
  ]
)

const useActionUrl = (
  actionId: MaybeRefOrGetter<number | null>,
  ...path: unknown[]
) => {
  const { createUrlWithCompanyId } = useUrl()

  return {
    createUrl: () => {
      return createUrlWithCompanyId(
        Endpoint.CompanyActions,
        toValue(actionId),
        ...path
      )
    },
  }
}

export const useActionQueryKey = (actionId: MaybeRef<number>): QueryKey => [
  Endpoint.CompanyActions,
  actionId,
]
